import React, { useState, useMemo } from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import Popover from 'components/uiLibrary/Popover';

import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './MediaAttribution.module.scss';

const MediaAttribution = ({ className, attribution }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [anchor, setAnchor] = useState(false);
  const { source, credit, copyright } = attribution || {};
  const domain = useMemo(() => {
    if (source) {
      const url = new URL(source);
      return url.hostname;
    }

    return null;
  }, [source]);

  if (!source && !credit && !copyright) {
    return null;
  }

  return (
    <div className={classnames(classes.root, { [className]: !!className })}>
      <div className={classes.source}>
        {domain && (
          <LinkButton title={t(`${TP}.FN_MEDIA_SOURCE`)} url={source} variant="text" disableUnderline external isLink>
            <Typography size={12} weight="medium" color="white">
              {domain}
            </Typography>
          </LinkButton>
        )}
      </div>
      <div className={classes.credit}>
        {(credit || copyright) && (
          <SpriteIcon
            className={classes.credit__icon}
            icon="copyright"
            size={18}
            onClick={e => setAnchor(e.currentTarget)}
          />
        )}
      </div>

      <Popover
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.creditInformation}>
          {credit && (
            <Typography variant="p">
              <Typography weight="bold">{t(`${TP}.FN_MEDIA_CREDIT`)}: </Typography>
              <Typography>{credit}</Typography>
            </Typography>
          )}
          {copyright && (
            <Typography variant="p">
              <Typography weight="bold">{t(`${TP}.FN_MEDIA_COPYRIGHT`)}: </Typography>
              <Typography>{copyright}</Typography>
            </Typography>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default MediaAttribution;
